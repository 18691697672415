(() => {
  // Selector to select the element that contains instances of `HTMLAnchorElement`
  const SIDE_NAV_SELECTOR = '#side-nav > .side-nav';
  const ACTIVE_SECTION_NAVIGATOR_CLASS = 'in-linked-section';

  type NullableRef<T> = {
    value: T | null;
  };

  const findInstanceOf = <T extends HTMLElement>(
    selector,
    isInstanceOf: new (...args: Array<any>) => T,
    searchOn: HTMLElement | Document = document
  ): T | null => {
    const element = searchOn.querySelector(selector);
    if (element instanceof isInstanceOf) {
      return element;
    }
    return null;
  };

  const findInstancesOf = <T extends HTMLElement>(
    selector,
    isInstanceOf: new (...args: Array<any>) => T,
    searchOn: HTMLElement | Document = document
  ): Array<T> => {
    const res: Array<T> = [];
    const elements = searchOn.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element instanceof isInstanceOf) {
        res.push(element);
      }
    }
    return res;
  };

  const handleSectionEnter = (
    sectionNavigator: HTMLAnchorElement,
    section: HTMLElement,
    currentSelectionNavigatorRef: NullableRef<HTMLAnchorElement>
  ) => {
    const observer = new IntersectionObserver(
      e => {
        if (!e[0].isIntersecting) {
          return;
        }
        currentSelectionNavigatorRef.value?.classList.remove(
          ACTIVE_SECTION_NAVIGATOR_CLASS
        );
        currentSelectionNavigatorRef.value = sectionNavigator;
        sectionNavigator.classList.add(ACTIVE_SECTION_NAVIGATOR_CLASS);
      },
      { threshold: 0.2 }
    );
    observer.observe(section);
  };

  const initSideNav = () => {
    const links = findInstancesOf(`${SIDE_NAV_SELECTOR} a`, HTMLAnchorElement);
    const currentSectionNavigatorRef: NullableRef<HTMLAnchorElement> = {
      value: null,
    };
    for (const link of links) {
      const section = findInstanceOf(link.getAttribute('href'), HTMLElement);
      if (section === null) {
        continue;
      }
      handleSectionEnter(link, section, currentSectionNavigatorRef);
      link.addEventListener('click', e => {
        // to prevent the browser from adding the ID to the url
        e.preventDefault();
        // scroll to the element manually since the default behavior is prevented
        section.scrollIntoView();
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initSideNav);
  } else {
    initSideNav();
  }
})();
