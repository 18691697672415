(() => {
  // wait for element to be present on the screen and resolves with a promise
  const waitForElm = (selector, parent?) => {
    return new Promise(resolve => {
      const wrapper = parent || document;
      const observeParent = parent || document.body;
      let el = wrapper.querySelector(selector);

      if (el) {
        resolve(el);
        return;
      }

      const observer = new MutationObserver(() => {
        if (wrapper?.querySelector(selector)) {
          resolve(wrapper.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(observeParent, {
        childList: true,
        subtree: true,
      });
    });
  };

  const handleImgMap = ($img: HTMLImageElement) => {
    let contentClass = 'img-map__content',
      markerMap = {},
      imgMapId = '',
      contentActiveClass = 'img-map__content--active',
      markerActiveClass = 'emu-mapping-marker--active',
      markerLineActiveClass = 'emu-mapping-marker__line--active',
      $markers: NodeListOf<HTMLElement>,
      $imgMain: HTMLElement,
      activeKey = '',
      $contentCloseCtas: NodeListOf<HTMLButtonElement>,
      isContentLeftAlignedInDesktop = false;

    // creating a marker object to keep track of the active marker
    const createMarkerObj = () => {
      const $markerContainer = $markers?.[0]?.closest(
        '.emu-image-map__map-marker-container'
      ) as HTMLElement;
      $markers?.forEach($marker => {
        const markerLabel = $marker.getAttribute('aria-label');
        const $content = $imgMain.querySelector(
          `.${contentClass}--${markerLabel}`
        ) as HTMLElement;

        if (markerLabel && $content instanceof HTMLElement) {
          $content.setAttribute('data-label', markerLabel);

          // setting default position for the content
          $content.style.top = $marker.style.top;
          $content.style.left = $marker.style.left;

          const $contentInner = $content.querySelector(
            '.img-map__content-inner'
          ) as HTMLElement;

          // adding marker line
          const $markerLine = document.createElement('span');
          $markerLine.classList.add('emu-mapping-marker__line');
          ($markerLine.style.left = $marker.style.left.replace(')', ' + 5px)')), // 5px is a fuzzy value to map with the image correctly
            ($markerLine.style.top = $marker.style.top);
          $markerContainer.append($markerLine);

          const contentWidth = $content.offsetWidth;
          markerMap[markerLabel] = {
            $content,
            $contentInner,
            markerLabel,
            $marker,
            $markerLine,
            markerLineWidth: $marker.style.left.replace(
              ')',
              ` - 50px - ${contentWidth}px + 5px)`
            ), // 50px is the additional space from the left, 262 is the width of the content, 5px is a fuzzy value to map with the image correctly
          };
        }
      });
    };

    // makes a marker active, adds appropriate classes to the body, marker and corresponding content
    const makeMarkerObjActive = markerObj => {
      activeKey = markerObj.markerLabel;

      const isRightAligned =
        markerObj.$contentInner?.getBoundingClientRect()?.left <= 10;
      markerObj.$content.classList.toggle(
        'img-map__content--right-align',
        isRightAligned
      );
      markerObj.$markerLine.classList.toggle(
        'emu-mapping-marker__line--right-align',
        isRightAligned
      );

      markerObj.$content.classList.add(contentActiveClass);
      markerObj.$marker.classList.add(markerActiveClass);
      markerObj.$markerLine.classList.add(markerLineActiveClass);
      if (isContentLeftAlignedInDesktop) {
        const contentWidth = markerObj.$content.offsetWidth;
        markerObj.$markerLine.style.width =
          markerObj.$marker.style.left.replace(
            ')',
            ` - 50px - ${contentWidth}px + 5px)`
          );
      }
    };

    // makes a marker inactive for a provided marker Object
    // if marker object is not provided, loops through the map, finds the active marker and makes it inactive
    const makeMarkerObjInactive = (markerObj?) => {
      let obj = markerObj;
      if (!obj) {
        Object.keys(markerMap).forEach(markerKey => {
          if (activeKey === markerKey) {
            obj = markerMap[markerKey];
          }
        });
      }

      if (obj?.$marker) {
        activeKey = '';
        obj.$content.classList.remove(contentActiveClass);
        obj.$marker.classList.remove(markerActiveClass);
        obj.$markerLine.classList.remove(markerLineActiveClass);
        if (isContentLeftAlignedInDesktop) {
          obj.$markerLine.style.width = '0px';
        }

        // timer to make sure that the animation is complete
        setTimeout(() => {
          obj.$content.classList.remove('img-map__content--right-align');
          obj.$markerLine.classList.remove(
            'emu-mapping-marker__line--right-align'
          );
        }, 400);
      }
    };

    const handleMapAreaClick = title => {
      const markerObj = markerMap[title];
      if (markerObj) {
        if (activeKey && activeKey === title) {
          makeMarkerObjInactive(markerObj);
        } else {
          if (activeKey) {
            makeMarkerObjInactive();
          }
          makeMarkerObjActive(markerObj);
        }
      }
    };

    const initVariables = () => {
      $imgMain = $img.closest('.img-map__main') as HTMLElement;
      isContentLeftAlignedInDesktop = $imgMain.classList.contains(
        'img-map__main--align-content-left-on-desktop'
      );
      const $imgMapContainer = $img.closest('.emu-image-map') as HTMLElement;
      $markers = $imgMapContainer?.querySelectorAll('.emu-mapping-marker');
      $contentCloseCtas = $imgMain?.querySelectorAll(
        '.img-map__content-close'
      ) as NodeListOf<HTMLButtonElement>;
      createMarkerObj();
      imgMapId = $img.getAttribute('data-id')!;
    };

    const appendEvents = () => {
      // when close button inside the content is clicked, make the marker inactive and hide the content
      $contentCloseCtas.forEach($el => {
        $el.addEventListener('click', e => {
          e.preventDefault();
          makeMarkerObjInactive();
        });
      });

      // when marker is clicked, show the corresponding content
      window.Bus.on('emu-image-map:areaClick', ({ id, title }) => {
        if (id?.includes?.(imgMapId)) {
          handleMapAreaClick(title);
        }
      });
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const imgMapElems = document.querySelectorAll(
      '.img-map__main'
    ) as NodeListOf<HTMLElement>;

    imgMapElems.forEach(imgMap => {
      // markers will not be available until JS from aaaem is processed.
      // waiting for the markers to appear before adding any functionality
      waitForElm('.emu-mapping-marker', imgMap).then(() => {
        const imgs = imgMap.querySelectorAll(
          '.emu-image-map'
        ) as NodeListOf<HTMLImageElement>;
        if (imgs?.length) {
          imgs.forEach($img => {
            handleImgMap($img);
          });
        }
      });
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
