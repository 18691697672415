// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './components/**/*.ts';
import './resources/images/favicon.png';
import './journey-page';
import './journey-page-banner';

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; // carousel
    _tabbyInstances: any; // tabs
    _plyrInstances: any; // vimeo embed
    _badgerInstances: any; // accordion
  }
}

(() => {
  // when close menu button is clicked, clicking the open, so that the menu will be toggled
  const handleMobileMenuClose = () => {
    const menuTrigger = document.querySelector(
      '#nav-mobile-trigger'
    ) as HTMLButtonElement;
    const isiLinksInHeader = document.querySelectorAll(
      'header.header a[href="#isi"]'
    ) as NodeListOf<HTMLAnchorElement>;

    window.Bus?.on('emu-button:click', ({ id }) => {
      if (id === 'nav-mobile-trigger-close') {
        menuTrigger?.click();
      } else if (id === 'nav-mobile-trigger') {
        document.body.classList.toggle('u-mobile-hide-overflow');
      }
    });

    // if the menu is in open state and if ISI links are clicked, close the menu
    isiLinksInHeader.forEach($el => {
      $el.addEventListener('click', () => {
        if (document.body.classList.contains('u-mobile-hide-overflow')) {
          menuTrigger?.click();
        }
      });
    });
  };

  // adds observer to add an active class to elements when they get visible
  const handleInviewObserver = () => {
    const classNames = {
      main: 'animate',
      active: 'animate--active',
      alwaysAnimate: 'animate--always',
    };

    const elems = document.querySelectorAll(`.${classNames.main}`);
    if (elems?.length) {
      elems.forEach(el => {
        const observer = new IntersectionObserver(
          entries => {
            const entry = entries[0];
            const { target } = entry;

            if (entry.isIntersecting) {
              target.classList.add(classNames.active);

              if (!target.classList.contains(classNames.alwaysAnimate)) {
                observer.unobserve(entry.target);
              }
            } else if (target.classList.contains(classNames.alwaysAnimate)) {
              target.classList.remove(classNames.active);
            }
          },
          {
            threshold: 0.4,
          }
        );

        observer.observe(el);
      });
    }
  };

  // adds observer to the footer, and adds a class to the body when footer is in view
  const handleFooterInview = () => {
    const $footer = document.querySelector('footer.footer') as HTMLElement;
    if ($footer) {
      const $body = document.body;
      const observer = new IntersectionObserver(
        entries => {
          const entry = entries[0];
          $body.classList.toggle('footer-in-view', entry.isIntersecting);
        },
        {
          threshold: 0,
        }
      );

      observer.observe($footer);
    }
  };

  // making sure that hash is not added to the URL when clicked
  const handleHashHrefs = () => {
    const allHashAnchors = document.querySelectorAll(
      'a[href="#"]'
    ) as NodeListOf<HTMLAnchorElement>;
    allHashAnchors.forEach(el => {
      el.addEventListener('click', e => {
        e?.preventDefault?.();
      });
    });
  };

  // preventing anchor page redirection on mobile menu for items that are level 1 anchors
  const preventPageRedirection = () => {
    const dropdownLinks = document.querySelectorAll(
      'header a:has(.nav-item.parent)'
    ) as NodeListOf<HTMLAnchorElement>;

    if (dropdownLinks?.length) {
      dropdownLinks.forEach(el => {
        el.addEventListener('click', e => {
          if (window.innerWidth < 1024) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            e.preventDefault();
          }
        });
      });
    }
  };

  // detecting Safari OS, as font weight 700 is giving a bolder rendering in Safari. Making sure the the font weight is always regular in safari browser
  const detectOS = () => {
    var ua = window.navigator.userAgent;
    var iOS =
      ua.match(/Macintosh/i) || ua.match(/iPad/i) || ua.match(/iPhone/i);
    var webkit = ua.match(/WebKit/i);
    var iOSSafari =
      iOS &&
      webkit &&
      !ua.match(/CriOS/i) &&
      !ua.match(/EdgiOS/i) &&
      !ua.match(/Chrome/i) &&
      !ua.match(/Edg/i);

    if (iOSSafari) {
      document.body.classList.add('is-safari');
    }
  };

  const init = () => {
    handleMobileMenuClose();
    handleInviewObserver();
    handleHashHrefs();
    preventPageRedirection();
    detectOS();
    handleFooterInview();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
