(() => {
  // when carousel has less content width than its container width, adding a class to hide arrows, dots and to center the content horizontally
  const adjustCarouselDimensions = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      const updateSliderHeight = inst => {
        //@ts-ignore
        const $activeSlideImages = inst
          .getInfo()
          ?.container?.querySelectorAll?.(
            '.tns-slide-active img'
          ) as NodeListOf<HTMLImageElement>;
        $activeSlideImages.forEach($img => {
          // making sure that the carousel height is updated when every image is loaded
          if ($img?.complete) {
            //@ts-ignore
            inst?.updateSliderHeight?.();
          } else if ($img) {
            $img.addEventListener('load', () => {
              //@ts-ignore
              inst?.updateSliderHeight?.();
            });
          }
        });
        inst?.updateSliderHeight?.();
      };

      carouseInstances.forEach(inst => {
        updateSliderHeight(inst);

        // making sure that the slider height is updated on every transition
        // @ts-ignore
        inst?.events?.on?.('indexChanged', () => {
          updateSliderHeight(inst);
        });
      });

      window.Bus?.on('emu-carousel:inview', ({ id }) => {
        const inst = window._tnsInstances?.[id];
        updateSliderHeight(inst);
      });
    }
  };

  // updates the TNS Live Region Text to include text in 1/8(current/total) format
  const addTnsLiveRegionText = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      carouseInstances.forEach(inst => {
        // @ts-ignore
        const curInfo = inst?.getInfo?.();

        const $liveRegion = curInfo?.container
          ?.closest?.('.carousel')
          ?.querySelector?.('.tns-liveregion') as HTMLElement;

        const updateLiveRegion = info => {
          try {
            const curIndex = info.displayIndex;
            const total = info.slideCount;
            if (isNaN(curIndex) !== true && isNaN(total) !== true) {
              $liveRegion.innerHTML = `${curIndex} OF ${total}`;
              $liveRegion.classList.add('can-show');
            } else if (isNaN(total) !== true) {
              $liveRegion.innerHTML = `1 OF ${total}`;
              $liveRegion.classList.add('can-show');
            } else {
              $liveRegion.classList.remove('can-show');
            }
          } catch (e) {
            console.warn(e);
            $liveRegion.classList.remove('can-show');
          }
        };

        if ($liveRegion) {
          // @ts-ignore
          inst?.events?.on?.('indexChanged', info => {
            updateLiveRegion(info);
          });

          updateLiveRegion(curInfo);
        }
      });
    }
  };

  const init = () => {
    adjustCarouselDimensions();
    addTnsLiveRegionText();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
