/**
 * This file contains the code to stop the page's default scroll until the banner animation is done.
 */

(() => {
  // dispatches a proxy scroll event
  let dispatchTimer;
  let proxyScrollEnabled;
  let banner;
  let scrollDir = 'down';
  let bannerAnimated = false;

  // adds and remove the banner animated class based on the scroll direction
  const dispatchProxyScrollEvent = dir => {
    if (dispatchTimer) {
      return;
    }

    if (dir === 'down') {
      banner.classList.add('jp__banner-wrapper--animated');
      bannerAnimated = true;
      // removing the timer
      dispatchTimer = setTimeout(() => {
        dispatchTimer = null;
        proxyScrollEnabled = false;
      }, 400);
    } else {
      banner.classList.remove('jp__banner-wrapper--animated');
      bannerAnimated = false;
    }
  };

  // when mouse wheel is scrolled. Accounts for laptop's touch pads too.
  // prevent the default scrolling and dispatch a scroll event when proxy scroll is enabled.
  // When proxy scroll is disabled, yet if the scroll position is zero, and user scrolls up dispatch a scroll event too
  const wheelScrollEvent = e => {
    if (proxyScrollEnabled) {
      e.preventDefault();
      let scrollDir = 'down';

      if (e.deltaY < 0) {
        scrollDir = 'up';
      }

      // scroll with sensitive touch pads gets triggered frequently. Adding a delta variable to make sure that the triggers are restricted to bigger values
      if (e.deltaY * Math.sign(e.deltaY) > 20) {
        dispatchProxyScrollEvent(scrollDir);
      }

      return;
    } else if (window.scrollY === 0 && e.deltaY < 0) {
      proxyScrollEnabled = true;
      dispatchProxyScrollEvent('up');
    }
  };

  // when keys are pressed to scroll the page
  // prevent the default scrolling and dispatch a scroll event when proxy scroll is enabled.
  // When proxy scroll is disabled, yet if the scroll position is zero, and user scrolls up dispatch a scroll event too
  // left: 37, up: 38, right: 39, down: 40,
  // space-bar: 32, page-up: 33, page-down: 34, end: 35, home: 36
  const keyCodes = [37, 38, 39, 40, 32, 33, 34, 35, 36];
  const upKeyCodes = [38, 33, 36, 37];
  const scrollPageWithKeys = e => {
    const curKeyCode = e.keyCode;
    if (proxyScrollEnabled) {
      if (keyCodes.indexOf(curKeyCode) >= 0) {
        e.preventDefault();

        let scrollDir = 'down';
        if (
          upKeyCodes.indexOf(curKeyCode) >= 0 ||
          (e.shiftKey && e.keyCode === 32)
        ) {
          scrollDir = 'up';
        }

        // for home and end keys, scroll to the top or end of the page
        if (e.keyCode === 36) {
          window.scrollTo({
            top: 0,
            behavior: 'instant',
          });
          return;
        } else if (e.keyCode === 35) {
          window.scrollTo({
            top: document.body.offsetHeight,
            behavior: 'instant',
          });
          return;
        }
        dispatchProxyScrollEvent(scrollDir);
        return;
      }
    } else if (window.scrollY === 0 && upKeyCodes.indexOf(curKeyCode) >= 0) {
      proxyScrollEnabled = true;
      dispatchProxyScrollEvent('up');
    }
  };

  // touch events when the page is scrolled
  // prevent the default scrolling and dispatch a scroll event when proxy scroll is enabled.
  // When proxy scroll is disabled, yet if the scroll position is zero, and user scrolls up dispatch a scroll event too
  let timer;
  let touchStartPosY = 0;
  const scrollPageWithTouch = e => {
    const currentPageY = Math.round(e.changedTouches[0].screenY);

    if (touchStartPosY === currentPageY) return;
    let scrollDir = 'down';
    if (touchStartPosY - currentPageY <= 0) {
      scrollDir = 'up';
    }
    if (proxyScrollEnabled) {
      e.preventDefault();
      e.stopPropagation();

      touchStartPosY = currentPageY;

      // debouncing to reduce frequent triggers
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        dispatchProxyScrollEvent(scrollDir);
      }, 50);

      return;
    } else if (window.scrollY === 0 && scrollDir === 'up') {
      proxyScrollEnabled = true;
      dispatchProxyScrollEvent('up');
    }
  };

  // appends events to handle the scroll of the page
  const appendScrollEvents = () => {
    // mouse wheel event
    document.addEventListener('wheel', wheelScrollEvent, {
      passive: false,
    });

    // touch move event
    window.addEventListener('touchmove', scrollPageWithTouch, {
      passive: false,
    });

    // keyboard event
    document.addEventListener('keydown', scrollPageWithKeys, false);
  };

  // scroll to top on page load and make first container active
  const onFirstLoad = () => {
    if (!window.location.hash) {
      proxyScrollEnabled = true;
      window.scroll({
        top: 0,
        behavior: 'instant',
      });
    } else {
      proxyScrollEnabled = window.scrollY === 0;
    }
  };

  const addDirectionTracker = () => {
    let curScrollPos = window.scrollY;
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY;
      scrollDir = scrollPos > curScrollPos ? 'down' : 'up';
      curScrollPos = scrollPos;
      proxyScrollEnabled = scrollPos === 0;

      if (proxyScrollEnabled) {
        dispatchProxyScrollEvent(scrollDir);
      } else if (bannerAnimated !== true) {
        // making sure that the animation is triaged when scrolled by dragging the scroll thumb
        dispatchProxyScrollEvent('down');
      }
    });
  };

  // initialization
  const initScrollManager = () => {
    // no need to manage scroll for other pages except journey page
    if (document.body.id !== 'journey-page') {
      return;
    }

    banner = document.querySelector('.jp__banner-wrapper');

    if (banner) {
      // adding scroll events
      appendScrollEvents();

      // scroll to top on page load
      onFirstLoad();

      // adds scroll direction to a variable
      addDirectionTracker();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initScrollManager);
  } else {
    initScrollManager();
  }
})();
